.wido-widget {
  background-color: transparent !important;
}

.wido-widget .button-large {
  color: #fff;
}

[color='accent'],
[color='accentSoft'],
[color='interactive'] {
  color: #fff !important;
  background-color: rgba(196, 196, 196, 0.01) !important;
  box-shadow: rgba(96, 68, 145, 0.3) 0px -63.1213px 52.3445px -49.2654px inset,
    rgba(202, 172, 255, 0.3) 0px 75.4377px 76.9772px -36.9491px inset,
    rgba(154, 146, 210, 0.3) 0px 3.07909px 13.8559px inset, rgba(227, 222, 255, 0.2) 0px 0.769772px 30.7909px inset !important;
}

.wido-widget [class^='Dialog__Modal-'] {
  background-color: rgb(20, 20, 81);
  /* Add any other styles you want */
}

.wido-widget [class^='ReverseButton__Underlayer-'] {
  background-color: transparent;
}

.wido-widget [class^='ReverseButton__Underlayer-'] button {
  border: 0 !important;
  box-shadow: rgba(96, 68, 145, 0.3) 0px -63.1213px 52.3445px -49.2654px inset,
    rgba(202, 172, 255, 0.3) 0px 75.4377px 76.9772px -36.9491px inset,
    rgba(154, 146, 210, 0.3) 0px 3.07909px 13.8559px inset, rgba(227, 222, 255, 0.2) 0px 0.769772px 30.7909px inset;
}
